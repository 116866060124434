<template>
  <main>
    <div class="first-body-section">
      <img
        class="fade-in"
        src="@/assets/careerspage/Group 2088.svg"
        alt=""
      />
    </div>
    <div class="careers-text-section">
        <span class="careers">Careers</span>
        <div>
            Reach We are constantly looking for amazing people and they <br> generally fill certain criteria:
        </div>
        <p>
            They are passionate about education in Africa, technology and media. Our team also places a <br>
            premium on people who are driven, results oriented, innovative and are genuinely nice and fun <br>
            to work with.
        </p>
        <span class="email">
            You can also email <a href="mailto:">careers@matriclive.com</a> with specific <br>enquiries.
        </span>
    </div>
  </main>
</template>

<script>
export default {
  name: "Careers",
}
  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main {
    background-image: url("../assets/careerspage/Desktop - 1.svg");
    background-size: auto;
}
.first-body-section {
  margin: 28px 0px;
  justify-content: space-around;
}

.first-body-section img {
  margin: 2% 0px;
  width: 719px;
  height: 480px;
}

.careers-text-section {
    text-align: initial;
    margin: 6%;
}

.careers-text-section .careers {
    text-align: initial;
    font-size: xxx-large;
    font-weight: bolder;
}

.careers-text-section > div {
    /* color: #f05a23; */
    color: mediumpurple;
    margin: 2% 0px;
    font-size: larger;
}

.careers-text-section .email > a {
  /* color: #f05a23; */
  color: mediumpurple;
}

.careers-text-section .email {
    font-size: small;
    font-weight: bold;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 950px) {
  .first-body-section img {
    width: 350px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
}
}
</style>
